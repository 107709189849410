import axios from 'axios'
import {

    CLIENT_COMPANY_LIST_REQUEST,
    CLIENT_COMPANY_LIST_SUCCESS,
    CLIENT_COMPANY_LIST_FAIL,

    CLIENT_COMPANY_CREATE_REQUEST,
    CLIENT_COMPANY_CREATE_SUCCESS,
    CLIENT_COMPANY_CREATE_FAIL,
    CLIENT_COMPANY_CREATE_RESET,

    CLIENT_COMPANY_DETAIL_REQUEST,
    CLIENT_COMPANY_DETAIL_SUCCESS,
    CLIENT_COMPANY_DETAIL_FAIL,
    CLIENT_COMPANY_DETAIL_RESET,

    CLIENT_COMPANY_UPDATE_REQUEST,
    CLIENT_COMPANY_UPDATE_SUCCESS,
    CLIENT_COMPANY_UPDATE_FAIL,
    CLIENT_COMPANY_UPDATE_RESET,

    CLIENT_COMPANY_DELETE_REQUEST,
    CLIENT_COMPANY_DELETE_SUCCESS,
    CLIENT_COMPANY_DELETE_FAIL,

    CLIENT_COMPANY_LIST_OPTIONS_REQUEST,
    CLIENT_COMPANY_LIST_OPTIONS_SUCCESS,
    CLIENT_COMPANY_LIST_OPTIONS_FAIL,
    
} from '../constants/clientCompaniesConstants'



export const listClientCompanies = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: CLIENT_COMPANY_LIST_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/jrss/client/`,
            config
        )
        
        dispatch({
            type: CLIENT_COMPANY_LIST_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({ 
            type: CLIENT_COMPANY_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
         })
    }
}


export const createClientCompany = (name, streetAddress, city, state, zipcode, contact, email, phoneNumber) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CLIENT_COMPANY_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            '/jrss/client/create/',
            {
                'name': name,
                'street_address': streetAddress,
                'city': city,
                'state': state,
                'zipcode': zipcode,
                'contact_person': contact,
                'email': email,
                'phone_number': phoneNumber
            },
            config
        )
        
        dispatch({
            type: CLIENT_COMPANY_CREATE_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        const errLen = Object.values(error.response.data.errors).length
        let payload = null
        if(errLen > 1) {
            const errorMessages = Object.values(error.response.data.errors).map((errorArray) => errorArray[0]);
            payload = errorMessages[0]
        }
        else {
            payload = Object.values(error.response.data.errors)
        }
        dispatch({ 
            type: CLIENT_COMPANY_CREATE_FAIL,
            payload: error.response && error.response.data.errors
                ? payload
                : error.response.data.detail ? error.response.data.detail
                : error.message,
         })
    }
}


export const getClientCompanyDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CLIENT_COMPANY_DETAIL_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/jrss/client/${id}/`,
            config
        )
        
        dispatch({
            type: CLIENT_COMPANY_DETAIL_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({ 
            type: CLIENT_COMPANY_DETAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
         })
    }
}


export const updateClientCompany = (client) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CLIENT_COMPANY_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/jrss/client/update/${client.id}/`,
            client,
            config
        )
        
        dispatch({
            type: CLIENT_COMPANY_UPDATE_SUCCESS,
        })

        dispatch({
            type: CLIENT_COMPANY_DETAIL_SUCCESS,
            payload: data
        })
        
    } catch (error) {

        const errLen = Object.values(error.response.data.errors).length
        let payload = null
        if(errLen > 1) {
            const errorMessages = Object.values(error.response.data.errors).map((errorArray) => errorArray[0]);
            payload = errorMessages[0]
        }
        else {
            payload = Object.values(error.response.data.errors)
        }
        
        dispatch({ 
            type: CLIENT_COMPANY_UPDATE_FAIL,
            payload: error.response && error.response.data.errors
                ? payload
                : error.response.data.detail ? error.response.data.detail
                : error.message,
         })
    }
}


export const deleteClientCompany = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CLIENT_COMPANY_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/jrss/client/delete/${id}/`,
            config
        )
        
        dispatch({
            type: CLIENT_COMPANY_DELETE_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        const errLen = Object.values(error.response.data.errors).length
        let payload = null
        if(errLen > 1) {
            const errorMessages = Object.values(error.response.data.errors).map((errorArray) => errorArray[0]);
            payload = errorMessages[0]
        }
        else {
            payload = Object.values(error.response.data.errors)
        }
        
        dispatch({ 
            type: CLIENT_COMPANY_DELETE_FAIL,
            payload: error.response && error.response.data.errors
                ? payload
                : error.response.data.detail ? error.response.data.detail
                : error.message,
         })
    }
}


export const getClientCompanyOptions = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: CLIENT_COMPANY_LIST_OPTIONS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/jrss/client/all/options/`,
            config
        )
        
        dispatch({
            type: CLIENT_COMPANY_LIST_OPTIONS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({ 
            type: CLIENT_COMPANY_LIST_OPTIONS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
         })
    }
}