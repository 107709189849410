import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import { USER_UPDATE_RESET } from '../constants/userConstants'
import { getUserDetails, updateUser } from '../actions/userActions'

function UserEditScreen() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userId = useParams()

    const [employeeId, setEmployeeId] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [isPayroll, setIsPayroll] = useState(false)
    const [isManager, setIsManager] = useState(false)
    // const [password, setPassword] = useState('')
    // const [confirmPassword, setConfirmPassword] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userDetail = useSelector(state => state.userDetail)
    const { error, loading, user } = userDetail

    const userUpdate = useSelector(state => state.userUpdate)
    const { error:errorUpdate, loading:loadingUpdate, success:successUpdate } = userUpdate

    useEffect(() => {
        if(!userInfo && !userInfo.is_manager ) {
            navigate('/jrss/employee/home')
        } else {
            if(successUpdate) {
                dispatch({type: USER_UPDATE_RESET })
                navigate('/jrss/employee/users')
            } else {
                if(!user.first_name || user.id !== Number(userId.id)) {
                    dispatch(getUserDetails(userId.id))
                } else {
                    setEmployeeId(user.employee_id)
                    setFirstName(user.first_name)
                    setLastName(user.last_name)
                    setEmail(user.email)
                    setIsPayroll(user.is_payroll)
                    setIsManager(user.is_manager)
                    // setPassword('')
                    // setConfirmPassword('')
                }
            }
        }

    }, [navigate, userInfo, successUpdate, user])


    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateUser({ 'id':userId.id, 'first_name':firstName, 'last_name':lastName, 'email':email, 'is_payroll':isPayroll, 'is_manager':isManager}))
        // if(password !== confirmPassword) {
        //     // setMessage('Passwords do not match')
        // } else {
        //     // dispatch(register(employeeId, firstName, lastName, email, isPayroll, isManager, password))
        //     dispatch(updateUser({ 'first_name':firstName, 'last_name':lastName, 'email':email, 'is_payroll':isPayroll, 'is_manager':isManager}))
        // }        
    }


  return (
    <div>
        <FormContainer>
            <h1>Edit User Profile</h1>
            {loadingUpdate && <Loader />}
            {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

                {loading ? <Loader /> :
                    error ? <Message variant='danger'>{error}</Message>
                : (
                    
                <Form onSubmit={submitHandler}>

                    <Form.Group controlId='employee_id'>
                        <Form.Label>Employee ID</Form.Label>
                        <Form.Control
                            disabled
                            type='text'
                            placeholder='employee id'
                            value={employeeId}
                        />
                    </Form.Group>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId='first_name'>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                required
                                type='name'
                                placeholder='Full Name'
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId='last_name'>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                required
                                type='name'
                                placeholder='Last Name'
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            </Form.Group>
                        </Col>
                    </Row>

                    

                    <Form.Group controlId='email'>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            required
                            type='email'
                            placeholder='sample@email.com'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    {/* <Form.Group controlId='password'>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            required
                            type='password'
                            placeholder='enter password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='confirmPassword'>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            required
                            type='password'
                            placeholder='confirm password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group> */}

                    <hr />
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId='isPayroll'>
                            <Form.Check
                                type='checkbox'
                                label='Payroll'
                                checked={isPayroll}
                                onChange={(e) => setIsPayroll(e.target.checked)}
                            />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId='isManager'>
                            <Form.Check
                                type='checkbox'
                                label='Manager'
                                checked={isManager}
                                onChange={(e) => setIsManager(e.target.checked)}
                            />
                            </Form.Group>
                        </Col>
                    </Row>
                    <hr />

                    <Button type='submit' variant='primary'>
                        Update User
                    </Button>
                </Form>
            )}
        </FormContainer>
    </div>
  )
}

export default UserEditScreen