import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import Select from 'react-select'
import { getClientCompanyOptions } from '../actions/clientCompanyActions'
import { createInvoice } from '../actions/invoiceActions'
import { INVOICE_CREATE_RESET } from '../constants/invoiceConstants'

function InvoiceCreateScreen() {
    const [clientCompanyId, setClientCompanyId] = useState('');
    const [lineItems, setLineItems] = useState([{ service: '', price: 0, quantity: 1, description: '' }]);

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const invoiceCreate = useSelector(state => state.invoiceCreate)
    const { error, loading, success } = invoiceCreate

    const clientCompanyListOptions = useSelector(state => state.clientCompanyListOptions)
    const { loading:loadingOptions, error:errorOptions, clientCompanyOptions } = clientCompanyListOptions

    useEffect(() => {
        if(!userInfo && !userInfo.is_manager ) {
            navigate('/jrss/employee/home')
        } else {
            if(success) {
                dispatch({type: INVOICE_CREATE_RESET})
                navigate('/jrss/invoices');
            } else {
                dispatch(getClientCompanyOptions());
            }
        }
    }, [dispatch, navigate, userInfo, success])


    const handleClientCompanyChange = (selectedOption) => {
        setClientCompanyId(selectedOption.value);
    };

    const handleLineItemChange = (index, field, value) => {
        const updatedLineItems = [...lineItems];
        updatedLineItems[index][field] = value;
        setLineItems(updatedLineItems);
    };

    const handleAddLineItem = () => {
        setLineItems([...lineItems, { service: '', price: 0, quantity: 1, description: '' }]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(createInvoice(clientCompanyId, lineItems))
    };

    return (
        <FormContainer>
            <h3>Create Invoice:</h3>

            {loadingOptions && <Loader />}
            {errorOptions && <Message variant='danger'>{errorOptions}</Message>}

            {loading ? <Loader /> :
                error ? <Message variant='danger'>{error}</Message>
            : (
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId='service'>
                        <Form.Label>Client Company:</Form.Label>
                        <Select
                            required
                            id="clientCompany"
                            options={clientCompanyOptions}
                            onChange={handleClientCompanyChange}
                        />
                    </Form.Group>
                    
                    <div>
                        <Form.Label>Line Items:</Form.Label>
                        {lineItems.map((item, index) => (
                            <Row key={index}>
                                <br />
                                <Col>
                                    <Form.Group controlId='service'>
                                        <Form.Label>Service:</Form.Label>
                                        <Form.Control
                                            required
                                            type='text'
                                            value={item.service}
                                            onChange={(e) => handleLineItemChange(index, 'service', e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Form.Group controlId='quantity'>
                                        <Form.Label>Quantity:</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={item.quantity}
                                            onChange={(e) => handleLineItemChange(index, 'quantity', e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Form.Group controlId='price'>
                                        <Form.Label>Price:</Form.Label>
                                        <Form.Control
                                            type="currency"
                                            value={item.price}
                                            onChange={(e) => handleLineItemChange(index, 'price', e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Form.Group controlId='description'>
                                        <Form.Label>Description:</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={1}
                                            value={item.description}
                                            onChange={(e) => handleLineItemChange(index, 'description', e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col style={{position: 'relative'}}>
                                    <Button style={{ position: 'absolute',bottom: 0}} className='btn btn-sm' type="button" onClick={handleAddLineItem}>
                                        Add Line Item
                                    </Button>
                                </Col>
                            </Row>
                        ))}
                        
                    </div>
                    <br />
                    <Button type="submit">Create Invoice</Button>
                </Form>
            )}
        </FormContainer>
    )
}

export default InvoiceCreateScreen