    import { Form, Formik } from "formik";
    import { useState } from 'react';
    import * as yup from 'yup';
    import TextField from "./TextField";
    import TextArea from "./TextArea";
    import SelectField from "./SelectField";
    import ErrorMessage from "./ErrorMessage";
    import axios from 'axios';
    import { contactUsServices } from '../../constants/contactRequestConstants';


    export default function ContactForm() {
        const [showSuccessMessage, setShowSuccessMessage] = useState(false);
        const [backendErrorMessage, setBackendErrorMessage] = useState("");

        const initialValues = {
            firstName: '',
            lastName: "",
            email: "",
            phoneNumber: "",
            service: contactUsServices[0],
            description: "",
        }

        const validationSchema = yup.object({
            firstName: yup.string().required('First name is required'),
            lastName: yup.string().required('Last name is required'),
            email: yup.string().email('Invalid email').required('Email is required'),
            phoneNumber: yup.string().required('Phone number is required'),
            service: yup.string().required('Service is required'),
            description: yup.string().required('Description is required'),
        });
        
        const onSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                const config = {
                    headers: {
                        'Content-type': 'application/json'
                    }
                }
                const response = await axios.post(
                    '/contactus/submit-contact/',
                    {
                        'full_name': `${values.firstName} ${values.lastName}`,
                        'email': values.email,
                        'phone_number': values.phoneNumber,
                        'service': values.service,
                        'description': values.description
                    },
                    config
                )
                const { success } = response.data;
        
                if (success) {
                    // Success case
                    setSubmitting(false); // Stops the loading indication
                    resetForm(); // Resets the form fields
                    setShowSuccessMessage(true); // Display the success message
                    setBackendErrorMessage(""); // Clear any previous error message
                }
        
            } catch (error) {
                setSubmitting(false); 
                setShowSuccessMessage(false); // Hide success message if visible
                
                // Extracting error message from the backend
                if (error.response && error.response.data && error.response.data.errors) {
                    const { errors } = error.response.data;
                    // Show a generic message for overall form errors
                    if(errors.__all__) {
                        setBackendErrorMessage(errors.__all__);
                    } else if(errors.email) {
                        // Or handle field-specific errors
                        setErrors({ email: errors.email });
                    }
                }
            }
        }

        return (
            <>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form className='contact_form font-ff md:p-8 p-4 rounded-lg drop-shadow-lg'>
                            {showSuccessMessage && (
                                <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-6" role="alert">
                                    <p className="font-bold">Success</p>
                                    <p>We have received your contact request and will reach out shortly!</p>
                                </div>
                            )}

                            <div className="space-y-6">
                                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-6">

                                    <div className="sm:col-span-3">
                                        <TextField label='First name' placeholder='Bonnie' name='firstName' />
                                        <ErrorMessage name='firstName' />
                                    </div>

                                    <div className="sm:col-span-3">
                                        <TextField label='Last name' placeholder='Clyde' name='lastName' />
                                        <ErrorMessage name='lastName' />
                                    </div>
                                    <div className="sm:col-span-3">
                                        <TextField label='Email'
                                            name="email" type={'email'} placeholder="contact@jankyrobotics.com" />
                                        <ErrorMessage name='email' />
                                    </div>
                                    <div className="sm:col-span-3">
                                        <TextField label='Phone number' name='phoneNumber' type={'tel'} placeholder='(123) 456-7890' />
                                        <ErrorMessage name='phoneNumber' />
                                    </div>
                                    
                                    <div className="sm:col-span-6">
                                        <SelectField 
                                            label='Service' 
                                            name='service' 
                                            placeholder='Select a service' 
                                            options={[
                                                { value: '', label: 'Select a service' },
                                                ...contactUsServices.map(service => ({ value: service, label: service }))
                                            ]}
                                        />
                                        <ErrorMessage name='service' />
                                    </div>
                                    <div className="sm:col-span-6">
                                        <TextArea label='Description' name='description' placeholder='Tell us more about your project' />
                                        <ErrorMessage name='description' />
                                    </div>
                                </div>

                                <button 
                                    type="submit" 
                                    className='bg-white border-white border text-primary-100 text-lg px-6 py-2.5 shadow-md rounded-md font-medium transition_linear hover:bg-primary-200 hover:text-white hover:border-primary-200'
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Sending...' : 'Send Message'}
                                </button>
                            </div>

                        </Form>
                    )}
                </Formik>
            </>
        )
    }
