export const statusChoices = {
    submitted: 'Submitted',
    read: 'Read',
    responded: 'Responded',
    delete: 'Delete',
};

export const contactUsServices = ['Web Application', 'Mobile App Devlopment', 'AI Integration', 'Data Analysis', 'Automation Testing', 'Digital Marketing', 'Consulting/Other']

export const CONTACT_REQUEST_LIST_SUCCESS = 'CONTACT_REQUEST_LIST_SUCCESS'; 
export const CONTACT_REQUEST_LIST_REQUEST = 'CONTACT_REQUEST_LIST_REQUEST';
export const CONTACT_REQUEST_LIST_FAIL = 'CONTACT_REQUEST_LIST_FAIL';

export const CONTACT_REQUEST_DETAIL_REQUEST = 'CONTACT_REQUEST_DETAIL_REQUEST'
export const CONTACT_REQUEST_DETAIL_SUCCESS = 'CONTACT_REQUEST_DETAIL_SUCCESS'
export const CONTACT_REQUEST_DETAIL_FAIL = 'CONTACT_REQUEST_DETAIL_FAIL'
export const CONTACT_REQUEST_DETAIL_RESET = 'CONTACT_REQUEST_DETAIL_RESET'


export const CONTACT_REQUEST_DELETE_REQUEST = 'CONTACT_REQUEST_DELETE_REQUEST'
export const CONTACT_REQUEST_DELETE_SUCCESS = 'CONTACT_REQUEST_DELETE_SUCCESS'
export const CONTACT_REQUEST_DELETE_FAIL = 'CONTACT_REQUEST_DELETE_FAIL'


export const CONTACT_REQUEST_UPDATE_REQUEST = 'CONTACT_REQUEST_UPDATE_REQUEST'
export const CONTACT_REQUEST_UPDATE_SUCCESS = 'CONTACT_REQUEST_UPDATE_SUCCESS'
export const CONTACT_REQUEST_UPDATE_FAIL = 'CONTACT_REQUEST_UPDATE_FAIL'
export const CONTACT_REQUEST_UPDATE_RESET = 'CONTACT_REQUEST_UPDATE_RESET'


export const STATUS_CHOICES_REQUEST = 'STATUS_CHOICES_REQUEST'
export const STATUS_CHOICES_SUCCESS = 'STATUS_CHOICES_SUCCESS'
export const STATUS_CHOICES_FAIL = 'STATUS_CHOICES_FAIL'



