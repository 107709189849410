import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { deleteUser, listUsers } from '../actions/userActions'

function ListUsersScreen() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userList = useSelector(state => state.userList)
    const { loading, error, users } = userList

    const userDelete = useSelector(state => state.userDelete)
    const { success:successDelete } = userDelete

    useEffect(() => {
        if(userInfo && userInfo.is_manager ) {
            dispatch(listUsers())
        } else {
            navigate('/jrss/employee/home')
        }

        
    }, [dispatch, navigate, successDelete, userInfo])

    const deleteHandler = (id) => {
        if(window.confirm('Are you sure you want to delete this user?')) {
            dispatch(deleteUser(id))
        }
    }

  return (
    <div>
        <Row>
            <Col>
                <h1>Users:</h1>
            </Col>

            <Col>
                
                <LinkContainer style={{float: 'right'}} to={`/jrss/employee/register/`} >
                    <Button variant='success' >
                        Create User
                    </Button>
                </LinkContainer>
            </Col>
        </Row>

        {loading ? 
            (<Loader />)
            : error ?
                (<Message variant='danger'>{error}</Message>)
                : (
                    <Table striped bordered hover responsive className='table-sm'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>NAME</th>
                                <th>EMAIL</th>
                                <th>Payroll</th>
                                <th>Manager</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {users.map(user => (
                                <tr key={user.id}>
                                    <td>{user.employee_id}</td>
                                    <td>{user.first_name} {user.last_name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.is_payroll ? (
                                            <i className='fas fa-check' style={{color:'green'}}></i>
                                        ) : (
                                            <i className='fas fa-check' style={{color:'red'}}></i>
                                        )}
                                    </td>
                                    <td>{user.is_manager ? (
                                            <i className='fas fa-check' style={{color:'green'}}></i>
                                        ) : (
                                            <i className='fas fa-check' style={{color:'red'}}></i>
                                        )}
                                    </td>
                                    <td>
                                        <LinkContainer to={`/jrss/employee/users/edit/${user.id}`} >
                                            <Button variant='info' className='btn-sm'>
                                                <i className='fas fa-edit'></i>
                                            </Button>
                                        </LinkContainer>

                                        <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(user.id)}>
                                            <i className='fas fa-trash'></i>
                                        </Button>
                                    </td>                                        
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )
        }
    </div>
  )
}

export default ListUsersScreen