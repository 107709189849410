import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import Select from 'react-select';
import { STATES } from '../constants/utilConstants';
import { getClientCompanyDetails, updateClientCompany } from '../actions/clientCompanyActions'
import { CLIENT_COMPANY_UPDATE_RESET } from '../constants/clientCompaniesConstants'

function ClientCompanyEditScreen() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const clientId = useParams()
    const [message, setMessage] = useState('')

    const [companyId, setCompanyId] = useState('')
    const [name, setName] = useState('')
    const [streetAddress, setStreetAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zipcode, setZipcode] = useState('')
    const [contact, setContact] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const clientCompanyDetail = useSelector(state => state.clientCompanyDetail)
    const { error, loading, clientCompany } = clientCompanyDetail

    const clientCompanyUpdate = useSelector(state => state.clientCompanyUpdate)
    const { error:errorUpdate, loading:loadingUpdate, success:successUpdate } = clientCompanyUpdate


    useEffect(() => {
        if (!userInfo || !userInfo.is_manager) {
          navigate('/jrss/employee/home');
          return;
        }
      
        if (successUpdate) {
          dispatch({ type: CLIENT_COMPANY_UPDATE_RESET });
          navigate('/jrss/client/companies');
          return;
        }
      
        if (!clientCompany.name || clientCompany.id !== Number(clientId.id)) {
          dispatch(getClientCompanyDetails(clientId.id));
          return;
        }
        
        setCompanyId(clientCompany.company_id);
        setName(clientCompany.name);
        setStreetAddress(clientCompany.street_address);
        setCity(clientCompany.city);
        setState(clientCompany.state);
        setZipcode(clientCompany.zipcode);
        setContact(clientCompany.contact_person);
        setEmail(clientCompany.email);
        setPhoneNumber(clientCompany.phone_number);
    }, [navigate, userInfo, successUpdate, clientCompany, clientId.id]);


    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateClientCompany({ 'id':clientId.id, 'name':name, 'street_address':streetAddress, 'city':city, 'state':state, 'zipcode':zipcode, 'contact_person':contact, 'email':email, 'phone_number':phoneNumber,}))
    }


    return (
        <FormContainer>
            <h1>Create Client Company</h1>
            {message && <Message variant='danger'>{message}</Message>}
            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader />}

            <Form onSubmit={submitHandler}>

                <Row>
                    <Col>
                        <Form.Group controlId='company_id'>
                            <Form.Label>Company ID:</Form.Label>
                            <Form.Control
                                disabled
                                type='text'
                                placeholder='company id'
                                value={companyId}
                                readOnly
                            />
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group controlId='companyName'>
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder=''
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                

                <br />

                <Row className='contact-info'>
                    <Col>
                        <Form.Group controlId='contact'>
                            <Form.Label>Company Contact:</Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder='Contact Name'
                                value={contact}
                                onChange={(e) => setContact(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId='email'>
                            <Form.Label>Contact's Email:</Form.Label>
                            <Form.Control
                                required
                                type='email'
                                placeholder='sample@email.com'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId='phoneNumber'>
                            <Form.Label>Contact's Phone #:</Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder='(123)456-7890'
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <br />

                <Row>
                    <Col>
                        <Form.Group controlId="streetAddress">
                            <Form.Label>Street Address</Form.Label>
                            <Form.Control 
                                required
                                type="text" 
                                placeholder="Enter street address" 
                                value={streetAddress}
                                onChange={(e) => setStreetAddress(e.target.value)}
                                />
                        </Form.Group>
                    </Col>
                </Row>
                
                <br />
                
                <Row>
                    <Col>
                        <Form.Group controlId="city">
                            <Form.Label>City:</Form.Label>
                            <Form.Control 
                                required
                                type="text" 
                                placeholder="City" 
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="state">
                            <Form.Label>State:</Form.Label>
                            <Form.Control 
                                required
                                type="text" 
                                placeholder="State" 
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="zipcode">
                            <Form.Label>Zip Code:</Form.Label>
                            <Form.Control 
                                required
                                type="text" 
                                placeholder="Zip Code" 
                                value={zipcode}
                                onChange={(e) => setZipcode(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <br />

                <Button type='submit' variant='info'>
                    Update Client
                </Button>
            </Form>
        </FormContainer>
    )
}

export default ClientCompanyEditScreen