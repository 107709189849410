import axios from 'axios'
import { 
    USER_LOGIN_SUCCESS,
    USER_LOGIN_REQUEST,
    USER_LOGIN_FAIL,

    USER_LOGOUT,

    USER_LIST_SUCCESS,
    USER_LIST_REQUEST,
    USER_LIST_FAIL,
    USER_LIST_RESET,

    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,

    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,

    USER_DETAIL_SUCCESS,
    USER_DETAIL_REQUEST,
    USER_DETAIL_FAIL,
    USER_DETAIL_RESET,

    USER_UPDATE_SUCCESS,
    USER_UPDATE_REQUEST,
    USER_UPDATE_FAIL,
    USER_UPDATE_RESET,

} from '../constants/userConstants';


export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post(
            '/jrss/staff/login/',
            {'email': email, 'password': password},
            config
        )
        
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))
        
    } catch (error) {
        dispatch({ 
            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
         })
    }
}


export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    dispatch({type: USER_LOGOUT})
    // dispatch({type: USER_DETAIL_RESET})
    // dispatch({type: ORDER_LIST_MY_RERSET})
    // dispatch({type: USER_LIST_RESET})    
}


export const listUsers = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIST_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/jrss/staff/`,
            config
        )
        
        dispatch({
            type: USER_LIST_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({ 
            type: USER_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
         })
    }
}


export const deleteUser = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/jrss/staff/delete/${id}/`,
            config
        )
        
        dispatch({
            type: USER_DELETE_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        const errLen = Object.values(error.response.data.errors).length
        let payload = null
        if(errLen > 1) {
            const errorMessages = Object.values(error.response.data.errors).map((errorArray) => errorArray[0]);
            payload = errorMessages[0]
        }
        else {
            payload = Object.values(error.response.data.errors)
        }
        
        dispatch({ 
            type: USER_DELETE_FAIL,
            payload: error.response && error.response.data.errors
                ? payload
                : error.response.data.detail ? error.response.data.detail
                : error.message,
         })
    }
}


export const register = (firstName, lastName, email, isPayroll, isManager, password) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_REGISTER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            '/jrss/staff/register/',
            {
                'first_name': firstName,
                'last_name': lastName,
                'email': email,
                'is_payroll': isPayroll,
                'is_manager': isManager,
                'password': password
            },
            config
        )
        
        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        const errLen = Object.values(error.response.data.errors).length
        let payload = null
        if(errLen > 1) {
            const errorMessages = Object.values(error.response.data.errors).map((errorArray) => errorArray[0]);
            payload = errorMessages[0]
        }
        else {
            payload = Object.values(error.response.data.errors)
        }
        dispatch({ 
            type: USER_REGISTER_FAIL,
            payload: error.response && error.response.data.errors
                ? payload
                : error.response.data.detail ? error.response.data.detail
                : error.message,
         })
    }
}


export const getUserDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DETAIL_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/jrss/staff/${id}/`,
            config
        )
        
        dispatch({
            type: USER_DETAIL_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({ 
            type: USER_DETAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
         })
    }
}


export const updateUser = (user) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/jrss/staff/update/${user.id}/`,
            user,
            config
        )
        
        dispatch({
            type: USER_UPDATE_SUCCESS,
        })

        dispatch({
            type: USER_DETAIL_SUCCESS,
            payload: data
        })
        
    } catch (error) {

        const errLen = Object.values(error.response.data.errors).length
        let payload = null
        if(errLen > 1) {
            const errorMessages = Object.values(error.response.data.errors).map((errorArray) => errorArray[0]);
            payload = errorMessages[0]
        }
        else {
            payload = Object.values(error.response.data.errors)
        }
        
        dispatch({ 
            type: USER_UPDATE_FAIL,
            payload: error.response && error.response.data.errors
                ? payload
                : error.response.data.detail ? error.response.data.detail
                : error.message,
         })
    }
}


export const updateUserProfile = (user) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/jrss/staff/update-profile/`,
            user,
            config
        )
        
        dispatch({
            type: USER_UPDATE_SUCCESS,
        })

        dispatch({
            type: USER_DETAIL_SUCCESS,
            payload: data
        })
        
    } catch (error) {

        const errLen = Object.values(error.response.data.errors).length
        let payload = null
        if(errLen > 1) {
            const errorMessages = Object.values(error.response.data.errors).map((errorArray) => errorArray[0]);
            payload = errorMessages[0]
        }
        else {
            payload = Object.values(error.response.data.errors)
        }
        
        dispatch({ 
            type: USER_UPDATE_FAIL,
            payload: error.response && error.response.data.errors
                ? payload
                : error.response.data.detail ? error.response.data.detail
                : error.message,
         })
    }
}