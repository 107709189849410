import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import { getInvoiceDetails, updateInvoice } from '../actions/invoiceActions'
import { INVOICE_UPDATE_RESET, invoiceStatuses } from '../constants/invoiceConstants'
import { formattedDate } from '../constants/utilConstants'

function InvoiceEditScreen() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const invoiceId = useParams()
    const [message, setMessage] = useState('')

    const [lineItems, setLineItems] = useState([{ service: '', price: 0, quantity: 1, description: '' }]);
    const [status, setStatus] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [updatedDate, setUpdatedDate] = useState('');
    const [invoiceTotal, setInvoiceTotal] = useState(0);

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const invoiceDetail = useSelector(state => state.invoiceDetail)
    const { error, loading, invoice } = invoiceDetail

    const invoiceUpdate = useSelector(state => state.invoiceUpdate)
    const { error:errorUpdate, loading:loadingUpdate, success:successUpdate } = invoiceUpdate

    useEffect(() => {
        if(!userInfo && !userInfo.is_manager ) {
            navigate('/jrss/employee/home')
        } else {

            if (successUpdate) {
                dispatch({ type: INVOICE_UPDATE_RESET });
                navigate('/jrss/invoices');
                return;
            } else {
                if (!invoice.invoice_number || invoice.id !== Number(invoiceId.id)) {
                    dispatch(getInvoiceDetails(invoiceId.id))
                    return;
                }
                // set all the fields of the invoice:
                setStatus(invoice.status);
                setInvoiceNumber(invoice.invoice_number);
                setCompanyName(invoice.client_company_name);
                setUpdatedDate(invoice.updated_at);
                setInvoiceTotal(invoice.invoice_total);
                setLineItems(invoice.line_items);
            }
        }
    }, [dispatch, navigate, userInfo, successUpdate, invoice, invoiceId.id])


    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateInvoice({ 'id':invoiceId.id, 'status': status}))
    }

    return (
        <FormContainer>
            <h1>Invoice Details</h1>
            {loadingUpdate && <Loader />}
            {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}


            {loading ? <Loader /> :
                error ? <Message variant='danger'>{error}</Message>
            : (
                <Form onSubmit={submitHandler}>

                    <Row>
                        <Col>
                            <Form.Group controlId='status'>
                                <Form.Label>Status</Form.Label>
                                <Form.Select value={status} onChange={(e) => {setStatus(e.target.value)}}>
                                    {Object.entries(invoiceStatuses).map(([key, value]) => (
                                        <option key={key} value={key}>
                                        {value}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group controlId='invoiceNumber'>
                                <Form.Label>Invoice Number:</Form.Label>
                                <Form.Control
                                    disabled
                                    type='text'
                                    value={invoiceNumber}
                                    readOnly
                                />
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group controlId='dateUpdated'>
                                <Form.Label>Date Updated:</Form.Label>
                                <Form.Control
                                    disabled
                                    type='text'
                                    value={formattedDate(updatedDate)}
                                    readOnly
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group controlId='companyName'>
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control
                                    disabled
                                    type='text'
                                    value={companyName}
                                    readOnly
                                >
                                </Form.Control>
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group controlId='invoiceTotal'>
                                <Form.Label>Total Due:</Form.Label>
                                <Form.Control
                                    disabled
                                    type='text'
                                    value={invoiceTotal}
                                    readOnly
                                >
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>

                    <div>
                        <Form.Label>Line Items:</Form.Label>

                        <Table striped bordered hover responsive className='table-sm'>
                            <thead>
                                <tr>
                                    <th style={{width: "20%"}}>SERVICE</th>
                                    <th style={{width: "20%"}}>QUANTITY</th>
                                    <th style={{width: "20%"}}>PRICE</th>
                                    <th style={{width: "20%"}}>TOTAL</th>
                                    <th style={{width: "20%"}}>DESCRIPTION</th>
                                </tr>
                            </thead>

                            <tbody>
                                {lineItems.map(item => (
                                    <tr key={item.id+item.service}>
                                        <td style={{width: "20%"}}>{item.service}</td>
                                        <td style={{textAlign: 'center', width: "20%"}}>{item.quantity}</td>
                                        <td style={{textAlign: 'right', width: "20%"}}>${item.price}</td>
                                        <td style={{textAlign: 'right', width: "20%"}}>${item.total}</td>
                                        <td style={{width: "20%"}}>{item.description}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        
                    </div>
                    <br />


                    <Row>
                        <Col>
                            <Button type='submit' variant='info'>
                                Update
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}
        </FormContainer>
    )
}

export default InvoiceEditScreen