import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { deleteClientCompany, listClientCompanies } from '../actions/clientCompanyActions'
import { formattedDate } from '../constants/utilConstants'


function ClientCompanyListScreen() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const clientCompanyList = useSelector(state => state.clientCompanyList)
    const { loading, error, clientCompanies } = clientCompanyList

    const clientCompanyDelete = useSelector(state => state.clientCompanyDelete)
    const { success:successDelete } = clientCompanyDelete

    useEffect(() => {
        if(userInfo && userInfo.is_manager ) {
            dispatch(listClientCompanies())
        } else {
            navigate('/jrss/employee/home')
        }

        
    }, [dispatch, navigate, userInfo, successDelete])

    
    const deleteHandler = (id) => {
        if(window.confirm('Are you sure you want to delete this company?')) {
            dispatch(deleteClientCompany(id))
        }
    }


    return (
        <div>
            <Row>
                <Col>
                    <h1>Client Companies:</h1>
                </Col>

                <Col>
                    
                    <LinkContainer style={{float: 'right'}} to={`/jrss/client/create/`} >
                        <Button variant='success' >
                            Create Company
                        </Button>
                    </LinkContainer>
                </Col>
            </Row>

            {loading ? 
                (<Loader />)
                : error ?
                    (<Message variant='danger'>{error}</Message>)
                    : (
                        <Table striped bordered hover responsive className='table-sm'>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>NAME</th>
                                    <th>CONTACT</th>
                                    <th>EMAIL</th>
                                    <th>PHONE</th>
                                    <th>CREATED</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {clientCompanies.map(company => (
                                    <tr key={company.id}>
                                        <td>{company.company_id}</td>
                                        <td>{company.name}</td>
                                        <td>{company.contact_person}</td>
                                        <td>{company.email}</td>
                                        <td>{company.phone_number}</td>
                                        <td>{formattedDate(company.created_at)}</td>
                                        <td>
                                            <LinkContainer to={`/jrss/client/edit/${company.id}`} >
                                                <Button variant='info' className='btn-sm'>
                                                    <i className='fas fa-edit'></i>
                                                </Button>
                                            </LinkContainer>

                                            <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(company.id)}>
                                                <i className='fas fa-trash'></i>
                                            </Button>
                                        </td>                                        
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )
            }
        </div>
    )
}

export default ClientCompanyListScreen