import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select';
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import { CLIENT_COMPANY_CREATE_RESET } from '../constants/clientCompaniesConstants'
import { STATES } from '../constants/utilConstants';
import { createClientCompany } from '../actions/clientCompanyActions';

function ClientCompanyCreateScreen() {
    const [name, setName] = useState('')
    const [streetAddress, setStreetAddress] = useState('')
    const [apartmentNumber, setApartmentNumber] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zipcode, setZipcode] = useState('')
    const [contact, setContact] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    
    const [message, setMessage] = useState('')
    
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const clientCompanyCreate = useSelector(state => state.clientCompanyCreate)
    const { error, loading, success } = clientCompanyCreate


    useEffect(() => {
        if(!userInfo && !userInfo.is_manager ) {
            navigate('/jrss/employee/home')
        } else {
            if(success) {
                dispatch({type: CLIENT_COMPANY_CREATE_RESET})
                navigate('/jrss/client/companies');
            }
        }

    }, [navigate, userInfo, success, dispatch])

    const submitHandler = (e) => {
        e.preventDefault()
        let fullAddress = streetAddress;
        // format street address:
        if(apartmentNumber) {
            const lowercaseApartmentNumber = apartmentNumber.toLowerCase();

            if (lowercaseApartmentNumber.includes("apt") || lowercaseApartmentNumber.includes("unit") || lowercaseApartmentNumber.includes("#")) {
                fullAddress = fullAddress.concat(" ").concat(apartmentNumber);
            } else {
                fullAddress = fullAddress.concat(' Unit ').concat(apartmentNumber);
            }
        }
        dispatch(createClientCompany(name, fullAddress, city, state.value, zipcode, contact, email, phoneNumber))
    }

    return (
        <FormContainer>
            <h1>Create Client Company</h1>
            {message && <Message variant='danger'>{message}</Message>}
            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader />}

            <Form onSubmit={submitHandler}>

                <Form.Group controlId='companyName'>
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                        required
                        type='text'
                        placeholder=''
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <br />

                <Row className='contact-info'>
                    <Col>
                        <Form.Group controlId='contact'>
                            <Form.Label>Company Contact:</Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder='Contact Name'
                                value={contact}
                                onChange={(e) => setContact(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId='email'>
                            <Form.Label>Contact's Email:</Form.Label>
                            <Form.Control
                                required
                                type='email'
                                placeholder='sample@email.com'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId='phoneNumber'>
                            <Form.Label>Contact's Phone #:</Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder='(123)456-7890'
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <br />

                <Row>
                    <Col>
                        <Form.Group controlId="streetAddress">
                            <Form.Label>Street Address</Form.Label>
                            <Form.Control 
                                required
                                type="text" 
                                placeholder="Enter street address" 
                                value={streetAddress}
                                onChange={(e) => setStreetAddress(e.target.value)}
                                />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="apartmentNumber">
                            <Form.Label>Unit #:</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="" 
                                value={apartmentNumber}
                                onChange={(e) => setApartmentNumber(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                
                <br />
                
                <Row>
                    <Col>
                        <Form.Group controlId="city">
                            <Form.Label>City:</Form.Label>
                            <Form.Control 
                                required
                                type="text" 
                                placeholder="City" 
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="state">
                            <Form.Label>State:</Form.Label>
                            <Select 
                                required
                                options={STATES} 
                                isSearchable={true} 
                                value={state}
                                onChange={(slectedOption) => setState(slectedOption)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="zipcode">
                            <Form.Label>Zip Code:</Form.Label>
                            <Form.Control 
                                required
                                type="text" 
                                placeholder="Zip Code" 
                                value={zipcode}
                                onChange={(e) => setZipcode(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <br />

                <Button type='submit' variant='primary'>
                    Create Client
                </Button>
            </Form>
        </FormContainer>
    )
}

export default ClientCompanyCreateScreen