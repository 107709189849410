import { 
    CONTACT_REQUEST_LIST_FAIL,
    CONTACT_REQUEST_LIST_REQUEST,
    CONTACT_REQUEST_LIST_SUCCESS,

    CONTACT_REQUEST_DETAIL_REQUEST,
    CONTACT_REQUEST_DETAIL_SUCCESS,
    CONTACT_REQUEST_DETAIL_FAIL,
    CONTACT_REQUEST_DETAIL_RESET,

    CONTACT_REQUEST_UPDATE_REQUEST,
    CONTACT_REQUEST_UPDATE_FAIL,
    CONTACT_REQUEST_UPDATE_RESET,
    CONTACT_REQUEST_UPDATE_SUCCESS,


} from '../constants/contactRequestConstants';


export const contactRequestListReducer = (state={contactRequests:[]}, action) => {
    switch(action.type){
        case CONTACT_REQUEST_LIST_REQUEST:
            return { loading:true }
        
        case CONTACT_REQUEST_LIST_SUCCESS:
            return { loading:false, contactRequests: action.payload }
        
        case CONTACT_REQUEST_LIST_FAIL:
            return { loading:false, error: action.payload }
        
        default:
            return state
    }
}


export const contactRequestDetailsReducer = (state={ contactRequest: {} }, action) => {
    switch(action.type){
        case CONTACT_REQUEST_DETAIL_REQUEST:
            return { ...state, loading:true }
        
        case CONTACT_REQUEST_DETAIL_SUCCESS:
            return {loading:false, contactRequest: action.payload}
        
        case CONTACT_REQUEST_DETAIL_FAIL:
            return {loading:false, error: action.payload}

        case CONTACT_REQUEST_DETAIL_RESET:
            return { contactRequest: {} }
        default:
            return state
    }
}


export const contactRequestUpdateReducer = (state={ contactRequest:{} }, action) => {
    switch(action.type){
        case CONTACT_REQUEST_UPDATE_REQUEST:
            return { loading:true }
        
        case CONTACT_REQUEST_UPDATE_SUCCESS:
            return {loading:false, success: true}
        
        case CONTACT_REQUEST_UPDATE_FAIL:
            return {loading:false, error: action.payload}

        case CONTACT_REQUEST_UPDATE_RESET:
            return { contactRequest: {  }}

        default:
            return state
    }
}