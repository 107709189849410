import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listInvoices } from '../actions/invoiceActions'
import { formattedDate } from '../constants/utilConstants'

function InvoiceListScreen() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const invoiceList = useSelector(state => state.invoiceList)
    const { loading, error, invoices } = invoiceList

    useEffect(() => {
        if(userInfo && userInfo.is_manager ) {
            dispatch(listInvoices())
        } else {
            navigate('/jrss/employee/home')
        }
    }, [dispatch, navigate, userInfo])


    
    return (
        <div>
            <Row>
                <Col>
                    <h1>Invoices:</h1>
                </Col>

                <Col>
                    
                    <LinkContainer style={{float: 'right'}} to={`/jrss/invoice/create/`} >
                        <Button variant='success' >
                            Create Invoice
                        </Button>
                    </LinkContainer>
                </Col>
            </Row>

            {loading ? 
                (<Loader />)
                : error ?
                    (<Message variant='danger'>{error}</Message>)
                    : (
                        <Table striped bordered hover responsive className='table-sm'>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>CLIENT NAME</th>
                                    <th>UPDATED</th>
                                    <th>CREATED</th>
                                    <th>STATUS</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {invoices.map(invoice => (
                                    <tr key={invoice.id}>
                                        <td>{invoice.invoice_number}</td>
                                        <td>{invoice.client_company_name}</td>
                                        <td>{formattedDate(invoice.updated_at)}</td>
                                        <td>{formattedDate(invoice.created_at)}</td>
                                        <td style={{ fontWeight: 'bold', color: 'blue' }}>{invoice.status.toUpperCase()}</td>
                                        <td>
                                            <LinkContainer to={`/jrss/invoice/edit/${invoice.id}`} >
                                                <Button variant='info' className='btn-sm'>
                                                    <i className='fas fa-edit'></i>
                                                </Button>
                                            </LinkContainer>
                                        </td>                                        
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )
            }
        </div>
    )
}

export default InvoiceListScreen