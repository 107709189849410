import './App.css';
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Header from './components/Header';
import Footer from './components/Footer';
import LoginScreen from './screens/LoginScreen';
import EmployeeHome from './screens/EmployeeHome';
import EditContactRequestScreen from './screens/EditContactRequestScreen';
import UserListScreen from './screens/UserListScreen';
import UserCreateScreen from './screens/UserCreateScreen';
import UserEditScreen from './screens/UserEditScreen';
import UserProfileScreen from './screens/UserProfileScreen';
import ClientCompanyListScreen from './screens/ClientCompanyListScreen';
import ClientCompanyCreateScreen from './screens/ClientCompanyCreateScreen';
import ClientCompanyEditScreen from './screens/ClientCompanyEditScreen';
import InvoiceListScreen from './screens/InvoiceListScreen';
import InvoiceCreateScreen from './screens/InvoiceCreateScreen';
import InvoiceEditScreen from './screens/InvoiceEditScreen';
import ContactScreen from './screens/HomeScreens/ContactScreen';
import ServicesScreen from './screens/HomeScreens/ServicesScreen';
import AboutScreen from './screens/HomeScreens/AboutScreen';
import MainLayout from './components/common/MainLayout';
import NotFoundScreen from './screens/HomeScreens/NotFoundScreen';
import HomeScreen from './screens/HomeScreens/HomeScreen';




function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path='/' exact element={<MainLayout><HomeScreen /></MainLayout>} />
        <Route path='/contact' element={<MainLayout><ContactScreen /></MainLayout>} />
        <Route path='/about' element={<MainLayout><AboutScreen /></MainLayout>} />
        <Route path='/services' element={<MainLayout><ServicesScreen /></MainLayout>} />
        <Route path='/*' element={<NotFoundScreen />} />

        <Route path='/jrss/employee/login' element={<LoginScreen />} />
        <Route path='/jrss/employee/home' element={<EmployeeHome />} />
        <Route path='/jrss/employee/profile' element={<UserProfileScreen />} />
        
        <Route path='/jrss/contact-request/:id/edit' element={<EditContactRequestScreen />} />
        
        <Route path='/jrss/employee/users' element={<UserListScreen />} />
        <Route path='/jrss/employee/users/edit/:id' element={<UserEditScreen />} />
        <Route path='/jrss/employee/register' element={<UserCreateScreen />} />

        <Route path='/jrss/client/companies' element={<ClientCompanyListScreen />} />
        <Route path='/jrss/client/create' element={<ClientCompanyCreateScreen />} />
        <Route path='/jrss/client/edit/:id' element={<ClientCompanyEditScreen />} />

        <Route path='/jrss/invoices' element={<InvoiceListScreen />} />
        <Route path='/jrss/invoice/create' element={<InvoiceCreateScreen />} />
        <Route path='/jrss/invoice/edit/:id' element={<InvoiceEditScreen />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
