import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listContactRequests } from '../actions/contactRequestActions';
import TableContainer from '../components/TableContainer'


function EmployeeHome() {

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const contactRequestList = useSelector(state => state.contactRequestList)
    const { loading, error, contactRequests } = contactRequestList

    const columns = ['full_name', 'email', 'phone_number', 'service', 'description', 'created_at', 'status']

    useEffect(() => {
        if(!userInfo) {
            navigate('/')
        } else {
            dispatch(listContactRequests())
        }
    }, [dispatch, navigate])

  return (
    <div>
         {loading ? 
            (<Loader />)
            : error ?
                (<Message variant='danger'>{error}</Message>)
                : 
                (
                    <div>
                        <TableContainer title='New Contact Requests' status='submitted' data={contactRequests} columns={columns} />
                        <hr />
                        <TableContainer title='Read Contact Requests' status='read' data={contactRequests} columns={columns} />
                        <hr />
                        <TableContainer title='Responded Contact Requests' status='responded' data={contactRequests} columns={columns} />
                    </div>
                )
        }
    </div>
  )
}

export default EmployeeHome