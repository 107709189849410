import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import { register } from '../actions/userActions'
import { USER_REGISTER_RESET } from '../constants/userConstants'


function UserRegisterScreen() {

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [isPayroll, setIsPayroll] = useState(false)
    const [isManager, setIsManager] = useState(false)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    
    const [message, setMessage] = useState('')
    
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userRegister = useSelector(state => state.userRegister)
    const { error, loading, success } = userRegister

    useEffect(() => {
        if(!userInfo && !userInfo.is_manager ) {
            navigate('/jrss/employee/home')
        } else {
            if(success) {
                dispatch({type: USER_REGISTER_RESET})
                navigate('/jrss/employee/users');
            }
        }

    }, [navigate, userInfo, success, dispatch])


    const submitHandler = (e) => {
        e.preventDefault()
        let locErr = false
        setMessage('')
        if(password !== confirmPassword) {
            setMessage('Passwords do not match')
            locErr = true
        }

        if(!locErr) {
            dispatch(register(firstName, lastName, email, isPayroll, isManager, password))
        }        
    }

  return (
    <div>
        <FormContainer>
            <h1>Create User</h1>
            {message && <Message variant='danger'>{message}</Message>}
            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader />}

            <Form onSubmit={submitHandler}>

                <Row>
                    <Col md={6}>
                        <Form.Group controlId='first_name'>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                required
                                type='name'
                                placeholder='Full Name'
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId='last_name'>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            required
                            type='name'
                            placeholder='Last Name'
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                        </Form.Group>
                    </Col>
                </Row>
                
                <Form.Group controlId='email'>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        required
                        type='email'
                        placeholder='sample@email.com'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='password'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        required
                        type='password'
                        placeholder='enter password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='confirmPassword'>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        required
                        type='password'
                        placeholder='confirm password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <hr />
                <Row>
                    <Col md={6}>
                        <Form.Group controlId='isPayroll'>
                        <Form.Check
                            type='checkbox'
                            label='Payroll'
                            checked={isPayroll}
                            onChange={(e) => setIsPayroll(e.target.checked)}
                        />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId='isManager'>
                        <Form.Check
                            type='checkbox'
                            label='Manager'
                            checked={isManager}
                            onChange={(e) => setIsManager(e.target.checked)}
                        />
                        </Form.Group>
                    </Col>
                </Row>
                <hr />

                <Button type='submit' variant='primary'>
                    Register
                </Button>
            </Form>
        </FormContainer>
    </div>
  )
}

export default UserRegisterScreen