import axios from 'axios'
import {

    INVOICE_LIST_REQUEST,
    INVOICE_LIST_SUCCESS,
    INVOICE_LIST_FAIL,

    INVOICE_CREATE_REQUEST,
    INVOICE_CREATE_SUCCESS,
    INVOICE_CREATE_FAIL,
    INVOICE_CREATE_RESET,

    INVOICE_DETAIL_REQUEST,
    INVOICE_DETAIL_SUCCESS,
    INVOICE_DETAIL_FAIL,
    INVOICE_DETAIL_RESET,

    INVOICE_UPDATE_REQUEST,
    INVOICE_UPDATE_SUCCESS,
    INVOICE_UPDATE_FAIL,
    INVOICE_UPDATE_RESET,

    INVOICE_DELETE_REQUEST,
    INVOICE_DELETE_SUCCESS,
    INVOICE_DELETE_FAIL,

} from '../constants/invoiceConstants'


export const listInvoices = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: INVOICE_LIST_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/jrss/invoice/`,
            config
        )
        
        dispatch({
            type: INVOICE_LIST_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({ 
            type: INVOICE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
         })
    }
}


export const createInvoice = (clientCompanyId, lineItems) => async (dispatch, getState) => {
    try {
        dispatch({
            type: INVOICE_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            '/jrss/invoice/create/',
            {
                'client_company': clientCompanyId,
                'line_items': lineItems,
            },
            config
        )
        
        dispatch({
            type: INVOICE_CREATE_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        const errLen = Object.values(error.response.data.errors).length
        let payload = null
        if(errLen > 1) {
            const errorMessages = Object.values(error.response.data.errors).map((errorArray) => errorArray[0]);
            payload = errorMessages[0]
        }
        else {
            payload = Object.values(error.response.data.errors)
        }
        dispatch({ 
            type: INVOICE_CREATE_FAIL,
            payload: error.response && error.response.data.errors
                ? payload
                : error.response.data.detail ? error.response.data.detail
                : error.message,
         })
    }
}


export const getInvoiceDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: INVOICE_DETAIL_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/jrss/invoice/${id}/`,
            config
        )
        
        dispatch({
            type: INVOICE_DETAIL_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({ 
            type: INVOICE_DETAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
         })
    }
}


export const updateInvoice = (invoice) => async (dispatch, getState) => {
    try {
        dispatch({
            type: INVOICE_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/jrss/invoice/update/${invoice.id}/`,
            invoice,
            config
        )
        
        dispatch({
            type: INVOICE_UPDATE_SUCCESS,
        })

        dispatch({
            type: INVOICE_DETAIL_SUCCESS,
            payload: data
        })
        
    } catch (error) {

        const errLen = Object.values(error.response.data.errors).length
        let payload = null
        if(errLen > 1) {
            const errorMessages = Object.values(error.response.data.errors).map((errorArray) => errorArray[0]);
            payload = errorMessages[0]
        }
        else {
            payload = Object.values(error.response.data.errors)
        }
        
        dispatch({ 
            type: INVOICE_UPDATE_FAIL,
            payload: error.response && error.response.data.errors
                ? payload
                : error.response.data.detail ? error.response.data.detail
                : error.message,
         })
    }
}


