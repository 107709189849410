import {

    CLIENT_COMPANY_LIST_REQUEST,
    CLIENT_COMPANY_LIST_SUCCESS,
    CLIENT_COMPANY_LIST_FAIL,

    CLIENT_COMPANY_CREATE_REQUEST,
    CLIENT_COMPANY_CREATE_SUCCESS,
    CLIENT_COMPANY_CREATE_FAIL,
    CLIENT_COMPANY_CREATE_RESET,

    CLIENT_COMPANY_DETAIL_REQUEST,
    CLIENT_COMPANY_DETAIL_SUCCESS,
    CLIENT_COMPANY_DETAIL_FAIL,
    CLIENT_COMPANY_DETAIL_RESET,

    CLIENT_COMPANY_UPDATE_REQUEST,
    CLIENT_COMPANY_UPDATE_SUCCESS,
    CLIENT_COMPANY_UPDATE_FAIL,
    CLIENT_COMPANY_UPDATE_RESET,

    CLIENT_COMPANY_DELETE_REQUEST,
    CLIENT_COMPANY_DELETE_SUCCESS,
    CLIENT_COMPANY_DELETE_FAIL,

    CLIENT_COMPANY_LIST_OPTIONS_REQUEST,
    CLIENT_COMPANY_LIST_OPTIONS_SUCCESS,
    CLIENT_COMPANY_LIST_OPTIONS_FAIL,
    
} from '../constants/clientCompaniesConstants'



export const clientCompaniesListReducer = (state={clientCompanies:[]}, action) => {
    switch(action.type){
        case CLIENT_COMPANY_LIST_REQUEST:
            return { loading:true }
        
        case CLIENT_COMPANY_LIST_SUCCESS:
            return {loading:false, clientCompanies: action.payload}
        
        case CLIENT_COMPANY_LIST_FAIL:
            return {loading:false, error: action.payload}
        default:
            return state
    }
}

 
export const clientCompanyCreateReducer = (state={ }, action) => {
    switch(action.type){
        case CLIENT_COMPANY_CREATE_REQUEST:
            return { loading:true }
        
        case CLIENT_COMPANY_CREATE_SUCCESS:
            return { loading:false, success:true }
        
        case CLIENT_COMPANY_CREATE_FAIL:
            return {loading:false, error: action.payload}

        case CLIENT_COMPANY_CREATE_RESET:
            return { loading: false, success: false }

        default:
            return state
    }
}


export const clientCompanyDetailReducer = (state={ clientCompany: {} }, action) => {
    switch(action.type){
        case CLIENT_COMPANY_DETAIL_REQUEST:
            return { ...state, loading:true }
        
        case CLIENT_COMPANY_DETAIL_SUCCESS:
            return { loading:false, clientCompany: action.payload }
        
        case CLIENT_COMPANY_DETAIL_FAIL:
            return { loading:false, error: action.payload }

        case CLIENT_COMPANY_DETAIL_RESET:
            return { clientCompany: {} }
        default:
            return state
    }
}


export const clientCompanyUpdateReducer = (state={ clientCompany:{} }, action) => {
    switch(action.type){
        case CLIENT_COMPANY_UPDATE_REQUEST:
            return { loading:true }
        
        case CLIENT_COMPANY_UPDATE_SUCCESS:
            return {loading:false, success: true}
        
        case CLIENT_COMPANY_UPDATE_FAIL:
            return {loading:false, error: action.payload}

        case CLIENT_COMPANY_UPDATE_RESET:
            return { clientCompany: {  }, error: null }

        default:
            return state
    }
}


export const clientCompanyDeleteReducer = (state={ }, action) => {
    switch(action.type){
        case CLIENT_COMPANY_DELETE_REQUEST:
            return { loading:true }
        
        case CLIENT_COMPANY_DELETE_SUCCESS:
            return {loading:false, success: true}
        
        case CLIENT_COMPANY_DELETE_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}



export const clientCompanyListOptionsReducer = (state={clientCompanyOptions:[]}, action) => {
    switch(action.type){
        case CLIENT_COMPANY_LIST_OPTIONS_REQUEST:
            return { loading:true }
        
        case CLIENT_COMPANY_LIST_OPTIONS_SUCCESS:
            return {loading:false, clientCompanyOptions: action.payload}
        
        case CLIENT_COMPANY_LIST_OPTIONS_FAIL:
            return {loading:false, error: action.payload}
        default:
            return state
    }
}
