import {

    INVOICE_LIST_REQUEST,
    INVOICE_LIST_SUCCESS,
    INVOICE_LIST_FAIL,

    INVOICE_CREATE_REQUEST,
    INVOICE_CREATE_SUCCESS,
    INVOICE_CREATE_FAIL,
    INVOICE_CREATE_RESET,

    INVOICE_DETAIL_REQUEST,
    INVOICE_DETAIL_SUCCESS,
    INVOICE_DETAIL_FAIL,
    INVOICE_DETAIL_RESET,

    INVOICE_UPDATE_REQUEST,
    INVOICE_UPDATE_SUCCESS,
    INVOICE_UPDATE_FAIL,
    INVOICE_UPDATE_RESET,

    INVOICE_DELETE_REQUEST,
    INVOICE_DELETE_SUCCESS,
    INVOICE_DELETE_FAIL,
    
} from '../constants/invoiceConstants'


export const invoiceListReducer = (state={invoices:[]}, action) => {
    switch(action.type){
        case INVOICE_LIST_REQUEST:
            return { loading:true }
        
        case INVOICE_LIST_SUCCESS:
            return {loading:false, invoices: action.payload}
        
        case INVOICE_LIST_FAIL:
            return {loading:false, error: action.payload}
        default:
            return state
    }
}


export const invoiceCreateReducer = (state={ }, action) => {
    switch(action.type){
        case INVOICE_CREATE_REQUEST:
            return { loading:true }
        
        case INVOICE_CREATE_SUCCESS:
            return { loading:false, success:true }
        
        case INVOICE_CREATE_FAIL:
            return {loading:false, error: action.payload}

        case INVOICE_CREATE_RESET:
            return { loading: false, success: false }

        default:
            return state
    }
}


export const invoiceDetailReducer = (state={ invoice: {} }, action) => {
    switch(action.type){
        case INVOICE_DETAIL_REQUEST:
            return { ...state, loading:true }
        
        case INVOICE_DETAIL_SUCCESS:
            return { loading:false, invoice: action.payload }
        
        case INVOICE_DETAIL_FAIL:
            return { loading:false, error: action.payload }

        case INVOICE_DETAIL_RESET:
            return { invoice: {} }
        default:
            return state
    }
}


export const invoiceUpdateReducer = (state={ invoice:{} }, action) => {
    switch(action.type){
        case INVOICE_UPDATE_REQUEST:
            return { loading:true }
        
        case INVOICE_UPDATE_SUCCESS:
            return {loading:false, success: true}
        
        case INVOICE_UPDATE_FAIL:
            return {loading:false, error: action.payload}

        case INVOICE_UPDATE_RESET:
            return { invoice: {  }, error: null }

        default:
            return state
    }
}