import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import { getUserDetails, updateUser, updateUserProfile } from '../actions/userActions'
import { USER_UPDATE_RESET } from '../constants/userConstants'

function UserProfileScreen() {
    
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userDetail = useSelector(state => state.userDetail)
    const { error, loading, user } = userDetail

    const userUpdate = useSelector(state => state.userUpdate)
    const { error:errorUpdate, loading:loadingUpdate, success:successUpdate } = userUpdate

    useEffect(() => {
        if (!userInfo) {
            navigate('/');
        } else {
            if(successUpdate) {
                dispatch({type: USER_UPDATE_RESET })
                navigate('/jrss/employee/home')
            } else {
                dispatch(getUserDetails(userInfo.id));
            }
        }
    }, [navigate, dispatch, userInfo, successUpdate, userInfo.id]);


    useEffect(() => {
        if(!user.first_name) {
            dispatch(getUserDetails(userInfo.id))
        } else {
            setFirstName(user.first_name)
            setLastName(user.last_name)
        }
    }, [user, dispatch, navigate, userInfo.id]);
    
    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateUserProfile({ 'id':userInfo.id, 'first_name':firstName, 'last_name':lastName}))
    }

  return (
    <div>
        <FormContainer>
            <h1>Edit Profile</h1>
            {loadingUpdate && <Loader />}
                {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}


                {loading ? <Loader /> :
                    error ? <Message variant='danger'>{error}</Message>
                : (
                    
                <Form onSubmit={submitHandler}>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId='first_name'>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                required
                                type='name'
                                placeholder='Full Name'
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId='last_name'>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                required
                                type='name'
                                placeholder='Last Name'
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button type='submit' variant='primary'>
                        Update
                    </Button>
                </Form>
            )}
        </FormContainer>
    </div>
  )
}

export default UserProfileScreen