export const invoiceStatuses = {
    draft: 'Draft',
    open: 'Open',
    paid: 'Paid',
    void: 'Void'
};


export const INVOICE_LIST_REQUEST = 'INVOICE_LIST_REQUEST'
export const INVOICE_LIST_SUCCESS = 'INVOICE_LIST_SUCCESS'
export const INVOICE_LIST_FAIL = 'INVOICE_LIST_FAIL'


export const INVOICE_CREATE_REQUEST = 'INVOICE_CREATE_REQUEST'
export const INVOICE_CREATE_SUCCESS = 'INVOICE_CREATE_SUCCESS'
export const INVOICE_CREATE_FAIL = 'INVOICE_CREATE_FAIL'
export const INVOICE_CREATE_RESET = 'INVOICE_CREATE_RESET'


export const INVOICE_DETAIL_REQUEST = 'INVOICE_DETAIL_REQUEST'
export const INVOICE_DETAIL_SUCCESS = 'INVOICE_DETAIL_SUCCESS'
export const INVOICE_DETAIL_FAIL = 'INVOICE_DETAIL_FAIL'
export const INVOICE_DETAIL_RESET = 'INVOICE_DETAIL_RESET'


export const INVOICE_UPDATE_REQUEST = 'INVOICE_UPDATE_REQUEST'
export const INVOICE_UPDATE_SUCCESS = 'INVOICE_UPDATE_SUCCESS'
export const INVOICE_UPDATE_FAIL = 'INVOICE_UPDATE_FAIL'
export const INVOICE_UPDATE_RESET = 'INVOICE_UPDATE_RESET'


export const INVOICE_DELETE_REQUEST = 'INVOICE_DELETE_REQUEST'
export const INVOICE_DELETE_SUCCESS = 'INVOICE_DELETE_SUCCESS'
export const INVOICE_DELETE_FAIL = 'INVOICE_DELETE_FAIL'


