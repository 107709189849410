import axios from 'axios';
import { 
    CONTACT_REQUEST_LIST_FAIL,
    CONTACT_REQUEST_LIST_REQUEST,
    CONTACT_REQUEST_LIST_SUCCESS,

    CONTACT_REQUEST_DETAIL_REQUEST,
    CONTACT_REQUEST_DETAIL_SUCCESS,
    CONTACT_REQUEST_DETAIL_FAIL,
    CONTACT_REQUEST_DETAIL_RESET,

    CONTACT_REQUEST_UPDATE_REQUEST,
    CONTACT_REQUEST_UPDATE_FAIL,
    CONTACT_REQUEST_UPDATE_RESET,
    CONTACT_REQUEST_UPDATE_SUCCESS,

    CONTACT_REQUEST_DELETE_REQUEST,
    CONTACT_REQUEST_DELETE_SUCCESS,
    CONTACT_REQUEST_DELETE_FAIL,

} from '../constants/contactRequestConstants';


export const listContactRequests = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTACT_REQUEST_LIST_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/jrss/staff/contactrequests/`,
            config
        )
        
        dispatch({
            type: CONTACT_REQUEST_LIST_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({ 
            type: CONTACT_REQUEST_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
         })
    }
}


export const getContactRequestDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTACT_REQUEST_DETAIL_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/contactus/contact-request/${id}/`,
            config
        )
        
        dispatch({
            type: CONTACT_REQUEST_DETAIL_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({ 
            type: CONTACT_REQUEST_DETAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
         })
    }
}


export const updateContactRequest = (contactRequest) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTACT_REQUEST_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/contactus/contact-request/update/${contactRequest.id}/`,
            contactRequest,
            config
        )
        
        dispatch({
            type: CONTACT_REQUEST_UPDATE_SUCCESS,
        })

        dispatch({
            type: CONTACT_REQUEST_DETAIL_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({ 
            type: CONTACT_REQUEST_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
         })
    }
}

export const deleteContactRequest = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTACT_REQUEST_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/contactus/contact-request/delete/${id}/`,
            config
        )
        
        dispatch({
            type: CONTACT_REQUEST_DELETE_SUCCESS,
        })

    } catch (error) {
        dispatch({ 
            type: CONTACT_REQUEST_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
         })
    }
}