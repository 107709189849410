import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import { deleteContactRequest, getContactRequestDetails, updateContactRequest } from '../actions/contactRequestActions'
import { CONTACT_REQUEST_UPDATE_RESET, statusChoices } from '../constants/contactRequestConstants';


function EditContactRequestScreen() {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const contactRequestId = useParams()

    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [phonenumber, setPhonenumber] = useState('');
    const [status, setStatus] = useState('');
    const [service, setService] = useState('');
    const [description, setDescription] = useState('');
    const [stageDelete, setStageDelete] = useState(false);

    const contactRequestDetail = useSelector(state => state.contactRequestDetail)
    const { error, loading, contactRequest } = contactRequestDetail

    const contactRequestUpdate = useSelector(state => state.contactRequestUpdate)
    const { error:errorUpdate, loading:loadingUpdate, success:successUpdate } = contactRequestUpdate


    useEffect(() => {
        if(successUpdate) {
            dispatch({type: CONTACT_REQUEST_UPDATE_RESET });
            navigate('/jrss/employee/home');
        } else {
            if(!contactRequest.status || contactRequest.id !== Number(contactRequestId.id)) {
                dispatch(getContactRequestDetails(contactRequestId.id))
            } else {
                setFullname(contactRequest.full_name);
                setEmail(contactRequest.email);
                setPhonenumber(contactRequest.phone_number);
                setService(contactRequest.service);
                setStatus(contactRequest.status);
                setDescription(contactRequest.description);
            }
        }
    }, [contactRequestId, contactRequest, successUpdate, navigate])


    const submitHandler = (e) => {
        e.preventDefault()
        if(stageDelete) {
            dispatch(deleteContactRequest(contactRequest.id))
            navigate('/jrss/employee/home');
        } else {
            dispatch(updateContactRequest({
                id: contactRequest.id,
                status: status
            }))
        }
    }

    const setStatusHandler = (e) => {
        e.preventDefault()
        setStageDelete(false);
        setStatus(e.target.value);

        if (e.target.value == 'delete') {
            setStageDelete(true);
        }
    }

    return (
        <FormContainer>
            <h1>Edit Contact Request</h1>
            {loadingUpdate && <Loader />}
            {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}


            {loading ? <Loader /> :
                error ? <Message variant='danger'>{error}</Message>
            : (
                <Form onSubmit={submitHandler}>

                    <Form.Group controlId='status'>

                        <Form.Label>Status</Form.Label>
                        <Form.Select value={status} onChange={setStatusHandler}>
                            {Object.entries(statusChoices).map(([key, value]) => (
                                <option key={key} value={key}>
                                {value}
                                </option>
                            ))}
                        </Form.Select>                      
                    </Form.Group>

                    <Form.Group controlId='fullname'>
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                            disabled
                            type='text'
                            placeholder='Full Name'
                            value={fullname}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='email'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            disabled
                            type='email'
                            placeholder='contact@email.com'
                            value={email}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='phonenumber'>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            disabled
                            type='text'
                            placeholder='(123) 456-7890'
                            value={phonenumber}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='service'>
                        <Form.Label>Service</Form.Label>
                        <Form.Control
                            disabled
                            type='text'
                            placeholder='Web App'
                            value={service}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='description'>
                        <Form.Label>Description</Form.Label>
                        <Form.Control 
                            disabled
                            as="textarea" 
                            rows={3}
                            placeholder='description'
                            value={description}
                        >
                        </Form.Control>
                    </Form.Group>
                    


                    {stageDelete ?
                        (
                            <Button type='submit' variant='danger'>
                                Delete
                            </Button>
                        ) 
                    : 
                        (
                        <Button type='submit' variant='info'>
                            Update
                        </Button>
                        )
                    }
                </Form>
            )}
        </FormContainer>
    )
}

export default EditContactRequestScreen