export const CLIENT_COMPANY_LIST_REQUEST = 'CLIENT_COMPANY_LIST_REQUEST'
export const CLIENT_COMPANY_LIST_SUCCESS = 'CLIENT_COMPANY_LIST_SUCCESS'
export const CLIENT_COMPANY_LIST_FAIL = 'CLIENT_COMPANY_LIST_FAIL'


export const CLIENT_COMPANY_CREATE_REQUEST = 'CLIENT_COMPANY_CREATE_REQUEST'
export const CLIENT_COMPANY_CREATE_SUCCESS = 'CLIENT_COMPANY_CREATE_SUCCESS'
export const CLIENT_COMPANY_CREATE_FAIL = 'CLIENT_COMPANY_CREATE_FAIL'
export const CLIENT_COMPANY_CREATE_RESET = 'CLIENT_COMPANY_CREATE_RESET'


export const CLIENT_COMPANY_DETAIL_REQUEST = 'CLIENT_COMPANY_DETAIL_REQUEST'
export const CLIENT_COMPANY_DETAIL_SUCCESS = 'CLIENT_COMPANY_DETAIL_SUCCESS'
export const CLIENT_COMPANY_DETAIL_FAIL = 'CLIENT_COMPANY_DETAIL_FAIL'
export const CLIENT_COMPANY_DETAIL_RESET = 'CLIENT_COMPANY_DETAIL_RESET'


export const CLIENT_COMPANY_UPDATE_REQUEST = 'CLIENT_COMPANY_UPDATE_REQUEST'
export const CLIENT_COMPANY_UPDATE_SUCCESS = 'CLIENT_COMPANY_UPDATE_SUCCESS'
export const CLIENT_COMPANY_UPDATE_FAIL = 'CLIENT_COMPANY_UPDATE_FAIL'
export const CLIENT_COMPANY_UPDATE_RESET = 'CLIENT_COMPANY_UPDATE_RESET'


export const CLIENT_COMPANY_DELETE_REQUEST = 'CLIENT_COMPANY_DELETE_REQUEST'
export const CLIENT_COMPANY_DELETE_SUCCESS = 'CLIENT_COMPANY_DELETE_SUCCESS'
export const CLIENT_COMPANY_DELETE_FAIL = 'CLIENT_COMPANY_DELETE_FAIL'


export const CLIENT_COMPANY_LIST_OPTIONS_REQUEST = 'CLIENT_COMPANY_LIST_OPTIONS_REQUEST'
export const CLIENT_COMPANY_LIST_OPTIONS_SUCCESS = 'CLIENT_COMPANY_LIST_OPTIONS_SUCCESS'
export const CLIENT_COMPANY_LIST_OPTIONS_FAIL = 'CLIENT_COMPANY_LIST_OPTIONS_FAIL'