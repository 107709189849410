import React from 'react';
import { Table, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'


const TableContainer = ({ title, columns, data, status }) => {
    const filteredData = data.filter((obj) => obj.status === status);
    
    return (
        <div>
        <h2>{title}</h2>
        <InnerTable columns={columns} data={filteredData} />
        </div>
    );
};


const InnerTable = ({ columns, data }) => {
    const formatColumnName = (columnName) => {
        const replaced = columnName.replace(/_/g, ' ');
        return replaced.replace(/\b\w/g, (char) => char.toUpperCase());
      };

      const formatDate = (datetimeString) => {
        const date = new Date(datetimeString);
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return date.toLocaleString(undefined, options);
      };

  return (
    <Table striped bordered hover responsive className='table-sm'>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{formatColumnName(column)}</th>
            ))}
            <th>View/Edit</th> 
          </tr>
        </thead>
        <tbody>
        {data.map((request, index) => (
            <tr key={index}>
              {columns.map((column, columnIndex) => (
                <td key={columnIndex}>
                  {column === 'created_at' ? formatDate(request[column]) : request[column]}
                </td>
              ))}
                <td>
                    <LinkContainer to={`/jrss/contact-request/${request.id}/edit`} >
                        <Button>
                            <i className='fa-solid fa-pen-to-square'></i>
                        </Button>
                    </LinkContainer>
                </td>
            </tr>
          ))}
        </tbody>
      </Table>
  );
};



export default TableContainer;